<script setup lang="ts">
import CampaignPopup from '@/views/campaign/Popup.vue'

const props = defineProps<Props>()

const apiUrlFile = `${useRuntimeConfig().public.apiBaseUrl}/storage/`
const userData = useCookie<any>('userData')

const selectedItem = ref()
const isCampaignVisible = ref(false)

interface Props {
  item: object
}
</script>

<template>
  <div>
    <VCardText>
      <VRow>
        <VCol
          cols="12"
          md="5"
          lg="5"
        >
          <div v-if="props.item.file.length">
            <VCard v-if="props.item.file.length < 2">
              <VImg
                v-for="image in props.item.file"
                :key="image.id"
                :src="apiUrlFile + image.url"
                lazy="true"
                cover
                height="190"
              />
            </VCard>
            <VCarousel
              v-else
              cycle
              hide-delimiters
              hide-delimiter-background
              height="auto"
            >
              <VCarouselItem
                v-for="image in props.item.file"
                :key="image.id"
              >
                <VCard>
                  <VImg
                    :src="apiUrlFile + image.url"
                    lazy="true"
                    cover
                    height="190"
                  />
                </VCard>
              </VCarouselItem>
            </VCarousel>
          </div>
          <div v-else>
            <VImg
              height="190"
              class="d-flex align-center text-center"
            >
              <VAvatar
                size="140"
                variant="tonal"
                rounded
                color="secondary"
                icon="tabler-photo-cancel"
              />
            </VImg>
          </div>
        </VCol>
        <VCol
          cols="12"
          md="5"
          lg="5"
        >
          <div>
            <div class="d-flex gap-2">
              <VChip
                v-if="props.item.type"
                :color="props.item.type.color"
                label
                variant="flat"
              >
                {{ props.item.type.name }}
              </VChip>

              <VChip
                v-if="props.item.start_date"
                color="primary"
                label
                variant="flat"
              >
                {{ $dayjs(props.item.start_date).month() + 1 }}月開催案内
              </VChip>
            </div>
            <h4 class="text-base font-weight-800 text-primary pt-2 pb-2">
              {{ props.item.name }}
            </h4>

            <div class="d-flex gap-2">
              期間
              <span>{{ `${$dayjs(props.item.start_date).format("MM月DD日 (dd)")}~${$dayjs(props.item.end_date).format("MM月DD日 (dd)")}` }}</span>
            </div>
            <h5 class="text-base font-weight-800 mt-2">
              {{ props.item.facility.name }}
            </h5>

            <div class="d-flex align-center mb-2">
              <div>
                <VIcon
                  color="primary"
                  icon="tabler-home"
                  size="18"
                />
              </div>
              <h5>{{ props.item.facility.address }}</h5>
            </div>

            <VBtn
              v-if="userData"
              @click="isCampaignVisible = true, selectedItem = props.item"
            >
              お問い合わせ
            </VBtn>

            <VBtn
              v-else
              :to="{ name: 'contact' }"
            >
              お問い合わせ
            </VBtn>
          </div>
        </VCol>
      </VRow>
    </VCardText>

    <CampaignPopup
      v-if="selectedItem"
      v-model:isCampaignVisible="isCampaignVisible"
      :is-admin="userData.role === 'admin' ? true : false"
      is-login
      :detail="selectedItem"
    />
  </div>
</template>
